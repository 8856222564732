import './App.css';
import { React } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Hjem } from './pages/Hjem';
import Ansatte, { ansatteLoader } from './pages/omoss/Ansatte';
import { OmOss } from './pages/omoss/OmOss';
import { Jobb } from './pages/omoss/Jobb';
import Prosjekter, { prosjekterLoader } from './pages/prosjekter/Prosjekter';
import Tjenester, { tjenesteLoader } from './pages/Tjenester';
import Salg, { salgLoader } from './pages/salg/Salg';
import Utleie, { utleieLoader } from './pages/Utleie';
import Layout from './layouts/Layout';
import LayoutOmOss from './layouts/LayoutOmOss';
import LayoutProsjekter from './layouts/LayoutProsjekter';
import LayoutSalg from './layouts/LayoutSalg';
import { Kontakt } from './pages/omoss/Kontakt';
import SalgDetaljer, { salgDetaljerLoader } from './pages/salg/SalgDetaljer';
import Feilmelding from './components/Feilmelding';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index loader={salgLoader} element={<Hjem />} />
      <Route path="om-oss" element={<LayoutOmOss />}>
        <Route index element={<OmOss />} />
        <Route path="ansatte" loader={ansatteLoader} element={<Ansatte />} />
        <Route path="kontakt" element={<Kontakt />} />
        <Route path="jobb" element={<Jobb />} />
      </Route>
      <Route path="prosjekter" element={<LayoutProsjekter />}>
        <Route index loader={prosjekterLoader} element={<Prosjekter />} />
      </Route>
      <Route path="tjenester" loader={tjenesteLoader} element={<Tjenester />} />
      <Route path="utleie" loader={utleieLoader} element={<Utleie />} />
      <Route path="salg" element={<LayoutSalg />} errorElement={<Feilmelding />}>
        <Route index loader={salgLoader} element={<Salg />} />
        <Route path=":id" loader={salgDetaljerLoader} element={<SalgDetaljer />} />
      </Route>



      <Route path="*" element={<Feilmelding />} />

    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;