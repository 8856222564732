import React from 'react'
import ItemListe from '../components/ItemListe';
import { useLoaderData } from 'react-router-dom';


export default function Tjenester() {

    const tjenester = useLoaderData()

    return (
        <>
            <ItemListe items={tjenester} />
        </>
    );
}

// Loader function
export const tjenesteLoader = async () => {
    /*
    const res = await fetch('http://localhost:4000/tjenester')

    if (!res.ok) {
        throw Error('Kunne ikke hente data')
    }

    return res.json()
    */

    const res = await fetch('data/tjenester.json')
    const data = await res.json()

    return data.tjenester
}