import { useState } from "react";
import { Button, Container, Row, Col, Image, Modal } from "react-bootstrap";
import { Icon } from '@iconify/react';

export default function Lightbox({
    show,
    heading,
    images,
    imageIndex,
    setImageIndex,
    eventHandler
}) {



    const controlHandler = (control) => {
        switch (control) {
            case 'next':
                console.log(imageIndex)
                if (imageIndex >= images.length - 1) {
                    setImageIndex(0)
                } else {
                    setImageIndex((prevState) => prevState + 1)
                }
                break
            case 'prev':
                console.log(imageIndex)
                if (imageIndex <= 0) {
                    setImageIndex(images.length - 1)
                } else {
                    setImageIndex((prevState) => prevState - 1)
                }
                break
            case 'close':
                eventHandler(false, 0)
                break
            default:
                setImageIndex(0)
        }
    }
    

    
    return (

        <Modal 
            show={show} 
            onHide={() => controlHandler('close')}
            size='xl'
        >
            <Modal.Header closeButton>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                    
                        <Button 
                            variant="link"
                            size="lg"
                            onClick={() => controlHandler('next')} 
                            className="lightboxArrowRight"
                        >
                            <Icon 
                                icon="fa6-solid:chevron-right" 
                            />
                        </Button>
                    
                    
                        <Button 
                            variant="link"
                            size="lg"
                            onClick={() => controlHandler('prev')} 
                            className="lightboxArrowLeft"
                        >
                            <Icon 
                                icon="fa6-solid:chevron-left" 
                            />
                        </Button>
                    
                    
                        {
                            images && images.map((item, index) => {
                                if (item.id == imageIndex) {

                                    return (
                                    <Image 
                                        key={item.id}
                                        src={require('../assets/img/salg/' + item.image)}
                                        alt={item.beskrivelse}
                                        fluid
                                    />
                                    )
                                }
                            })
                        }
                    
                    
            </Modal.Body>
            
            <Modal.Footer>
                
                    
                        {
                            images && images.map((item, index) => (
                                <Col xs={2}>
                                    <Image 
                                        key={item.id}
                                        src={require('../assets/img/salg/' + item.image)}
                                        alt={item.beskrivelse}
                                        onClick={() => setImageIndex(item.id)}
                                        className="lightbox-thumbnail"
                                    />
                                </Col>
                            ))
                        }
                    
                
            </Modal.Footer>
            
        </Modal>

    )
}