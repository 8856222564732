import React from 'react';
import { Navigation } from '../components/Navigation';
import Footer from '../components/Footer';
import SectionDivider from '../components/SectionDivider';
import { Outlet } from 'react-router-dom';

export default function Layout() {
    return (
        
            <div className="layout">
                <header>
                    <Navigation />
                </header>
                
                <SectionDivider />
                
                <main>
                    <Outlet />
                </main>
                
                <SectionDivider />
                
                <footer>
                    <Footer />
                </footer>
            </div>
        
    )
}

