import { useLoaderData, Link } from "react-router-dom";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";

export default function Salg() {

    const salgsobjekter = useLoaderData()
    salgsobjekter.sort((a, b) => a.sortOrder - b.sortOrder)

    return (
        <div className="salgsoversikt">
            {salgsobjekter.map((obj, index) => {
                if (obj.aktivFlagg) {
                    return (
                        <div key={obj.id}>
                            <Container className="my-5">
                                <Row className="p-4 align-items-center rounded-3 border shadow-lg">
                                    <Col xs={12} lg={6} className="">
                                        <h1 className="display-6">{obj.navn}</h1>
                                        {obj.beskrivelseKort && (<p className="lead">{obj.beskrivelseKort}</p>)}
                                        {obj.beskrivelseLang && (<p>{obj.beskrivelseLang}</p>)}
                                        {obj.pris && (<p>{obj.pris}</p>)}
                                        {obj.subPageFlagg && (
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                                                <Link to={obj.id} className="btn btn-secondary btn-lg px-4 me-md-2 fw-bold" role="button">Mer informasjon</Link>
                                            </div>
                                        )}
                                    </Col>
                                    {obj.images && (
                                        <Col xs={12} lg={6} className="p-0 ">
                                            <Carousel>
                                                {obj.images.map((b, i) => (
                                                    <Carousel.Item key={b.id}>
                                                        <Image src={require('../../assets/img/salg/' + b.image)} fluid />
                                                        <Carousel.Caption>
                                                            <h3>{obj.navn}</h3>
                                                            <p>{b.beskrivelse}</p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                        </div>
                    )
                } else {
                    return null
                }
            })}
        </div>
    )
}

// Loader function
export const salgLoader = async () => {
    /*
    const res = await fetch('http://localhost:4000/salgsobjekter')

    if (!res.ok) {
        throw Error('Kunne ikke hente data')
    }

    return res.json()
    */

    /*
    const data = await import("../../data/salgsobjekter.json");
    console.log(data.salgsobjekter);
    return data.salgsobjekter;
    */

    const res = await fetch('data/salgsobjekter.json')
    const data = await res.json()

    console.log(data)

    return data.salgsobjekter
    
}