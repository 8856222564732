import { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Lightbox from "../../components/Lightbox";

export default function SalgDetaljer() {

    const { id } = useParams()
    const obj = useLoaderData()

    const [show, setShow] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const lightboxHandler = (show, imageIndex) => {
        setShow(show);
        setImageIndex(imageIndex);
    };

    return (
        <>
            <div className="salgDetaljer">
                <Container>
                    <Row className="my-4">
                        <Col lg={6} className="align-self-center">
                            <h1 className="display-5">{obj.navn}</h1>
                            <p className="lead">{obj.beskrivelseKort}</p>
                            <p>{obj.beskrivelseLang}</p>

                            {obj.beskrivelseListe && (
                                <ul className="list-unstyled">
                                    {obj.beskrivelseListe.map((t, i) => (
                                        <li key={i}>{t.tekst}</li>
                                    ))}
                                </ul>
                            )}

                            <p className="lead">{obj.pris}</p>

                            {obj.faktaListe && (
                                <>
                                    <h2 className="h5 pt-4">Enheter</h2>
                                    <dl className="row">
                                        {obj.faktaListe.map((f, i) => (
                                            <>
                                                <dt className="col-sm-3">{f.tittel}</dt>
                                                <dd className="col-sm-9">{f.tekst}</dd>
                                            </>
                                        ))}
                                    </dl>
                                </>
                            )}


                        </Col>

                        {obj.images && (
                            <Col lg={6}>
                                <Row className="justify-content-center">

                                    {obj.images.map((item, index) => {
                                        if (item.hovedbildeFlagg === true) {
                                            return (
                                                <Col key={item.id} xs={12} className="px-1">
                                                    <Image 
                                                        src={require('../../assets/img/salg/' + item.image)} 
                                                        onClick={() => {
                                                            lightboxHandler(true, item.id)
                                                        }}
                                                        fluid 
                                                        rounded 
                                                    />
                                                </Col>
                                            )
                                        } else {
                                            return (
                                                <Col key={item.id} xs={3} className="mt-2 px-1">
                                                    <Image 
                                                        src={require('../../assets/img/salg/' + item.image)} 
                                                        onClick={() => {
                                                            lightboxHandler(true, item.id)
                                                        }}
                                                        fluid 
                                                        rounded 
                                                    />
                                                </Col>
                                            )
                                        }
                                    })}

                                </Row>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>

           
            <Lightbox
                show={show}
                heading={obj.navn}
                images={obj.images}
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
                eventHandler={lightboxHandler}
            />
        </>
    )
}

// loader function
export const salgDetaljerLoader = async ({ params }) => {
    const { id } = params

    /*
    const res = await fetch('http://localhost:4000/salgsobjekter/' + id)

    if (!res.ok) {
        throw Error('Prosjektet finnes ikke')
    }

    return res.json()
    */

    /*
    const res = await import("../../data/salgsobjekter.json")
    const data = res.salgsobjekter.filter((obj) => obj.id === id)
    console.log(data[0])
    return data[0]
    */

    const res = await fetch('../../data/salgsobjekter.json')
    const data = await res.json()

    console.log(id)
    console.log(data)

    const dataObj = data.salgsobjekter.filter((obj) => obj.id === id)

    console.log(dataObj[0])

    return dataObj[0]
}