import React from 'react';
import Row from 'react-bootstrap/Row';
import AnsatteListe from '../../components/AnsatteListe';
import { Container } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';

const Ansatte = () => {

    const ansatte = useLoaderData()

    return (


        <Container className="ansatteliste">
            <Row xs={1} md={2} lg={3} xl={4} className="g-5">
                <AnsatteListe ansatte={ansatte} />
            </Row>
        </Container>

    );
}

export default Ansatte;

// Loader function
export const ansatteLoader = async () => {
    /*
    const res = await fetch('http://localhost:4000/ansatte')

    if (!res.ok) {
        throw Error('Kunne ikke hente data')
    }
    

    return res.json()
    */

    const res = await fetch('../data/ansatte.json', { redirect: "error" })
    const data = await res.json()

    return data.ansatte

    
}