import React from 'react'


function PlaceholderImage() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 150" width="200" height="150">
    <rect width="200" height="150" fill="#cccccc"></rect>
    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="monospace" font-size="26px" fill="#333333">200x150</text>   
</svg>
</>
    );
}

export default PlaceholderImage;


