import { Container } from "react-bootstrap"
import { Link, useRouteError } from "react-router-dom"
import SectionDivider from "./SectionDivider"
import { Kontakt } from "../pages/omoss/Kontakt"

export default function Feilmelding() {

    const error = useRouteError()

    return (
        <div>
            <Container>
                <div className="feilmelding">
                <h2>Noe gikk galt</h2>
                <p className="lead">Noen har beklageligvis gjemt siden du letet etter så godt at vi ikke klarte å finne den.</p>
                <p className="lead">Finner du ikke det du leter etter på nettsiden så ta gjerne kontakt med oss i stedet.</p>
                {/*<p>Feilmelding: {error.message}</p>*/}
                <Link to="/" className="lead">Tilbake til hjemmesiden</Link>
                </div>
            </Container>
            
            <SectionDivider />

            <Kontakt />

        </div>
    )
}