import React from 'react';
import { Container, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavDropdown } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import logo from '../assets/img/logo-heller.svg';

export const Navigation = () => {
    return (
        <>
        <Navbar expand="lg">
            <Container>
                <NavbarBrand href="/">
                    <img src={logo} height="100" alt="logo" />
                </NavbarBrand>
                <NavbarToggle aria-controls="basic-navbar-nav" />
                <NavbarCollapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <NavItem><NavLink href="/">Hjem</NavLink></NavItem>
                        <NavDropdown title="Om oss" id="nav-dropdown-omoss">
                            <NavDropdown.Item href="/om-oss/">Om oss</NavDropdown.Item>
                            <NavDropdown.Item href="/om-oss/ansatte">Ansatte</NavDropdown.Item>
                            <NavDropdown.Item href="/om-oss/jobb">Jobbe hos oss</NavDropdown.Item>
                            <NavDropdown.Item href="/om-oss/kontakt">Kontakt oss</NavDropdown.Item>
                        </NavDropdown>
                        <NavItem><NavLink href="/tjenester">Tjenester</NavLink></NavItem>
                        <NavItem><NavLink href="/salg">Til salgs</NavLink></NavItem>
                        <NavItem><NavLink href="/utleie">Utleie</NavLink></NavItem>
                        <NavItem><NavLink href="/prosjekter">Prosjekter</NavLink></NavItem>
                    </Nav>
                </NavbarCollapse>
            </Container>
        </Navbar>
        
        </>
    )
}