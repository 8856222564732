import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

export const Jobb = () => {
    return (

        <Container>
            <Row>
                <Col>
                    <h1>Jobb hos oss</h1>

                    <p>Vi er alltid på jakt etter dyktige medarbeidere.</p>

                    <p>Hos oss får du muligheten til å være en del av et faglig sterkt miljø som verdsetter både faglig utvikling og trivsel på arbeidsplassen.</p>
                    <p>Vi har mange varierende oppdrag (se «prosjekter»), som byr på spennende utfordringer.</p>
                    <p>Vi har korte besluttningsveier.</p>
                    <p>Sammen skaper vi bygg vi kan være stolte av!</p>
                    <p>Godt arbeidsmiljø er svært viktig for oss. Derfor har vi gjennom året samlinger som sørger for dette, blant annet quiz eventer og sportslige arrangementer.</p>
                    <p>Gode lønnsbetingelser for den rette.</p>
                </Col>
            </Row>

{/* 
            <Row>
                <Col>
                    <h2>Hvorfor søke jobb hos oss?</h2>
                    <h3 className='h4'>Varierende prosjekter</h3>
                    <p>
                        Vi jobber med et bredt spekter som byr på en variert arbeidshverdag.
                    </p>

                    <h3 className='h4'>Innovasjon</h3>
                    <p>
                        Vi setter pris på innovasjon og oppmuntrer til nyskapende tanker. Din kreativitet er viktig
                        for oss.
                    </p>

                    <h3 className='h4'>Arbeidsmiljø</h3>
                    <p>
                        Vi legger vekt på et inkluderende og positivt arbeidsmiljø der hver enkelt føler seg
                        verdsatt.
                    </p>
                </Col>
            </Row>

*/}

            <Row>
                <Col>
                    <p className='pt-4'><strong>Send oss gjerne en åpen søknad for å se om vi har en stilling som kan passe deg!</strong></p>
                </Col>
            </Row>

        </Container>


    )
}