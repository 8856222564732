import React from 'react';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import CarouselProjects from '../components/CarouselProjects';
import SectionDivider from '../components/SectionDivider';
import { Kontakt } from './omoss/Kontakt';
import { Link, useLoaderData } from 'react-router-dom';


export const Hjem = () => {

    const salgsobjekter = useLoaderData()

    return (
        <>

            {/* 
            <CarouselProjects />
            <SectionDivider />
            */}

            

            <Container>

                <h1>Heller Entreprenør</h1>
                <p>
                    Ta kontakt med oss for å få skreddersydd dine byggeønsker.
                </p>
                <p>
                    Vi er pålitelige innen prosjektledelse, byggesøknader, og konstruksjonsarbeid.
                </p>
                <p>
                    Enten du drømmer om å
                    realisere ditt drømmehjem, bygge på huset, eller modernisere eksisterende eiendom, er
                    vi her for å hjelpe deg.
                </p>
                <p>
                    Vi forstår at hvert prosjekt er unikt. Våre løsninger blir
                    skreddersydd etter ønsker og behov.
                </p>


            </Container>

            <SectionDivider />

            <Container>
                <Row>
                    <h1>Prosjekter for salg</h1>
                </Row>
                <Row>
                    <hr />
                </Row>
                
                <Row xs={1} md={2} className='g-4'>
                    {salgsobjekter.map((item, index) => {
                        if (item.featuredFlagg) {
                            return (
                                
                                <Col key={item.id}>
                                    
                                    <Link to={'salg/' + item.id} style={{ textDecoration: 'none' }}>
                                        
                                        <Card className='border shadow'>
                                            {item.images && item.images.map((b, i) => {

                                                if (b.hovedbildeFlagg) {
                                                    return (
                                                        <Card.Img variant="top" key={b.id} src={require('../assets/img/salg/' + b.image)} />
                                                    )
                                                } else {
                                                    return (
                                                        null
                                                    )
                                                }

                                            })}

                                            <Card.Body>
                                                <Card.Title>{item.navn}</Card.Title>
                                                <Card.Text>{item.beskrivelseKort}</Card.Text>
                                                <Card.Text>{item.beskrivelseLang}</Card.Text>
                                            </Card.Body>
                                            
                                            <Card.Footer>{item.pris}</Card.Footer>
                                        
                                        </Card>
                                    
                                    </Link>
                                
                                </Col>
                            )
                        }
                    })}
                
                </Row>

            </Container>


            <SectionDivider />

            <div className="kontakt-hjem">
                <Kontakt />
            </div>

        </>

    )
}