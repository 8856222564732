import React from 'react';
import { Container, Row, Col, Navbar, Nav, NavItem, NavLink } from 'react-bootstrap';


const Footer = () => {

    return (

        <Container>

            <Navbar className="justify-content-center border-bottom py-3 mt-1">
                <Nav>
                    <NavItem><NavLink href="/">Hjem</NavLink></NavItem>
                    <NavItem><NavLink href="/om-oss/kontakt">Kontakt oss</NavLink></NavItem>
                </Nav>
            </Navbar>

            <p className="text-center text-body-secondary py-2 my-2">&copy; 2023 Heller Entreprenør AS</p>

        </Container>

    );
}

export default Footer;


