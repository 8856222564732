import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SectionDivider from './SectionDivider';
import { Link } from 'react-router-dom';


const ItemListe = (props) => {

    const items = props.items;
    console.log(items)

    return (
        <>


            {items.map((item, index) => (
                <div key={item.id}>

                    {index % 2 === 0 ? (
                        <>
                            {index !== 0 ? (<SectionDivider />) : (null)}
                            <Container>
                                <Row className="flex-lg-row-reverse align-items-center">
                                    { item.image && (
                                    <Col className="col-12 col-sm-8 col-lg-6">
                                        <img src={require('../assets/' + item.imageFolder + item.image)} className="d-block mx-lg-auto img-fluid" alt={item.navn} width="700" height="500" loading="lazy" />
                                    </Col>
                                    )}
                                    <Col className="col-lg-6 my-auto align-middle">

                                        <h1 className="display-5 mb-3">{item.navn}</h1>
                                        { item.beskrivelse && (<p className="lead">{item.beskrivelse}</p>)}
                                        <>
                                            { item.objekter && (
                                                <ul>
                                                    {item.objekter.map((i, idx) => (
                                                        <li key={idx}>{i.navn}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            
                                        </>
                                        <>
                                            { item.subPage && (
                                                <Link to={item.subPage} className="btn btn-secondary" role="button">Mer informasjon</Link>
                                            )}

                                        </>

                                    </Col>
                                </Row>
                            </Container>
                        </>
                    ) : (
                        <>
                            <SectionDivider />
                            <Container>
                                <Row className="flex-lg-row align-items-center">
                                    {item.image && (
                                    <Col className="col-12 col-sm-8 col-lg-6">
                                        <img src={require('../assets/' + item.imageFolder + item.image)} className="d-block mx-lg-auto img-fluid" alt={item.navn} width="700" height="500" loading="lazy" />
                                    </Col>
                                    )}
                                    <Col className="col-lg-6 my-auto align-middle">

                                        <h1 className="display-5 mb-3">{item.navn}</h1>
                                        {item.beskrivelse && (<p className="lead">{item.beskrivelse}</p>)}
                                        <>
                                            {item.objekter && (
                                                <ul>
                                                    {item.objekter.map((i, idx) => (
                                                        <li key={idx}>{i.navn}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </>
                                        <>
                                            {item.subPage && (
                                                <Link to={item.subPage} className="btn btn-secondary" role="button">Mer informasjon</Link>
                                            )}
                                        </>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )}


                    {/* <div className="py-3"><hr /></div> */}
                </div>
            )
            )}
        </>
    );
}

export default ItemListe;