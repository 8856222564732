import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLoaderData } from 'react-router-dom';
import { Card } from 'react-bootstrap';


const Prosjekter = () => {

    const prosjekter = useLoaderData()

    return (
        
        <Container>
            <Row>
                <Col>
                    <h1 className='pb-4'>Noen av våre gjennomførte prosjekter</h1>
                </Col>
            </Row>
            <Row xs={1} md={2} className='g-4'>
                {prosjekter.map((item, index) => {
                    return (
                        <Col key={item.id}>
                            <Card className="border shadow">
                                {item.image &&
                                    <Card.Img variant="top" src={require('../../assets/img/prosjekter/' + item.image)} onError={null} />
                                }
                                    <Card.Body>
                                        <Card.Title>{item.navn}</Card.Title>
                                    </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    

    )
}

export default Prosjekter;

// Loader function
export const prosjekterLoader = async () => {
    /*
    const res = await fetch('http://localhost:4000/prosjekter')

    if (!res.ok) {
        throw Error('Kunne ikke hente data')
    }

    return res.json()
    */

    const res = await fetch('data/prosjekter.json')
    const data = await res.json()

    return data.prosjekter
}