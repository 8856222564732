import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

const AnsatteListe = (props) => {

    const ansatte = props.ansatte;
    ansatte.sort((a,b) => a.id - b.id)
    

    return (
        <>
            {ansatte.map((ansatt) => (
                <Col key={ansatt.id}>
                    <Card className="py-3 px-3 rounded-3 border shadow" key={ansatt.id}>
                        <img
                            src={require( '../assets/' + ansatt.imageFolder + ansatt.image )}
                            className="img-fluid"
                            alt={ansatt.navn}
                        />
                        <Card.Body>
                            <Card.Title>{ansatt.navn}</Card.Title>
                            <Card.Text>
                                {ansatt.tittel}
                            </Card.Text>
                            
                                
                                { ansatt.telefon ? (
                                    <Card.Text>Tlf: {ansatt.telefon}</Card.Text>
                                ) : (
                                    null
                                )}

                                { ansatt.epost ? (
                                    <Card.Text>E-post:<br /> {ansatt.epost}</Card.Text>
                                ) : (
                                    null
                                )}
                            
                            
                        </Card.Body>
                    </Card>
                </Col>
            ))}


        </>
    );
}

export default AnsatteListe;