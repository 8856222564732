import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GoogleMaps from '../../components/GoogleMaps';
import { Icon } from '@iconify/react';

export const Kontakt = () => {

    const iconSize = "24"

    return (
        <>

            <Container className="kontaktContainer">
                <Row>
                    <Col className="col-12 col-lg-5 mt-4 mb-4 my-auto">
                        <Row className="kontaktrad">
                            <Col>
                                <Row>
                                    <Col><Icon icon="bi:buildings-fill" width={iconSize} height={iconSize} /></Col>
                                </Row>
                            </Col>
                            <Col xs={10}>
                                <Row>
                                    <Col xs={10}><h1 className='h4'>Heller Entreprenør AS</h1></Col>
                                </Row>
                                <Row>
                                    <Col xs={10}><p>Trøgstadveien 121</p></Col>
                                </Row>
                                <Row>
                                    <Col xs={10}><p>1814 Askim</p></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="kontaktrad">
                            <Col><Icon icon="bi:telephone-fill" alt="telefon" width={iconSize} height={iconSize} /></Col>
                            <Col xs={10}><p>69 88 02 64</p></Col>
                        </Row>
                        <Row className="kontaktrad">
                            <Col><Icon icon="simple-icons:maildotru" width={iconSize} height={iconSize} /></Col>
                            <Col xs={10}><p>post@hellerentreprenor.no</p></Col>
                        </Row>
                        <Row className="kontaktrad">
                            <Col><Icon icon="fa6-solid:users" width={iconSize} height={iconSize} /></Col>
                            <Col xs={10}><p>Org.nr.: 931 584 383 MVA</p></Col>
                        </Row>
                    </Col>
                    <Col className="col-lg-7">
                        <GoogleMaps />
                    </Col>
                </Row>







            </Container>

        



        </>

    )
}