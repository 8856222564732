import React from 'react'
import { Container, Row, Col, Image} from 'react-bootstrap'
import jumboImage from '../../assets/img/ansatte/omoss.png';
import Jumbotron from '../../components/Jumbotron';

export const OmOss = () => {
    return (
        <>
            <Image src={jumboImage} fluid />
            
            <Container className="omoss">
                <Row>
                    <Col>
                        <h1>Historie og bakgrunn</h1>
                        <p>
                            Ing. S. Heller AS ble etabler 1. april 1980. Først som enkeltmannsforetak og to år etter omdannet til AS. <br />
                            De første årene var det hovedsakelig tilbygg, ombygging av eneboliger og eneboliger.
                            Siden den gang har vi bygget kirkebygg, næringsbygg, kommunale bygg og leilighetsbygg mm.
                        </p>
                        <p>
                            Vi omsetter for 30-50 millioner i året og er 10 faste ansatte.
                        </p>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <h1>Visjon</h1>
                        <p>Vi ønsker å heve standarden for byggekvalitet og skape verdier som varer, for våre
                            kunder og samfunnet.</p>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <h1>Verdier</h1>
                        <h3 className="h4">Kvalitet</h3>
                        <p>Vi streber alltid etter å levere høyeste kvalitet, og vi er stolte av vårt arbeid. Dette
                            inkluderer både håndverket selv og måten vi samhandler med kunder og kolleger på.</p>

                        <h3 className="h4">Kundefokus</h3>
                        <p>Våre kunder er vår høyeste prioritet, og vi arbeider for å forstå og imøtekomme deres
                            behov.</p>

                        <h3 className="h4">Samhold</h3>
                        <p>Vi arbeider som ett team, både internt og med våre kunder og samarbeidspartnere.</p>

                        <h3 className="h4">Sikkerhet</h3>
                        <p>Vi prioriterer sikkerhet for våre ansatte, kunder og prosjekter gjennom interne
                            retningslinjer.</p>

                        <h3 className="h4">Pålitelighet</h3>
                        <p>Våre kunder kan stole på oss for å levere som avtalt og oppfylle våre forpliktelser.</p>

                        <h3 className="h4">Bærekraft</h3>
                        <p>Vi tar ansvar for vår påvirkning på miljøet og arbeider for bærekraftige praksiser.</p>

                        <h3 className="h4">Engasjement</h3>
                        <p>Vi engasjerer oss helhjertet i vårt arbeid og er stolte av det vi oppnår.</p>

                        <h3 className="h4">Respekt</h3>
                        <p>Vi respekterer hverandre, våre kunder og vårt samfunn, og vi opprettholder en
                            inkluderende arbeidskultur.</p>

                        <h3 className="h4">Ansvarlighet</h3>
                        <p>Vi tar ansvar for våre handlinger og tar initiativ til å løse problemer.</p>

                        <h3 className="h4">Innovasjon</h3>
                        <p>Å være åpne for nye arbeidsmetoder, materialer og løsninger bidrar til å skille oss
                            ut og forbedrer våre tjenester.</p>
                    </Col>
                </Row>



            </Container>
        </>
    )
}