import React from 'react'
import { useLoaderData } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Kontakt } from './omoss/Kontakt';


const Utleie = () => {

    const utleieobjekter = useLoaderData()

    return (
    <>
        <Container>
            <Row>
                <Col>
                    <h1>Utleie</h1>
                    <p>Vi har et stort utvalg utstyr tilgjengelig for utleie. Blant annet:</p>
                </Col>
            </Row>

            {utleieobjekter.map((item, index) => {
                return (
                    <Row key={item.id}>
                        <Col>
                            <h2 className='h4'>{item.navn}</h2>

                            {item.objekter && (
                                <ul>
                                    {item.objekter.map((obj, idx) => (
                                        <li key={idx}>
                                            {obj.navn}
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </Col>
                    </Row>
                )
            })}
        </Container>

        
        <Container>
            <Row>
                <Col>
                    <h2>Kontakt oss så finner vi en løsning på ditt behov</h2>
                    <Kontakt />
                </Col>
            </Row>
        </Container>
    
    </>
    );
}

export default Utleie;


// Loader function
export const utleieLoader = async () => {
    /*
    const res = await fetch('http://localhost:4000/utleie')

    if (!res.ok) {
        throw Error('Kunne ikke hente data')
    }

    return res.json()
    */

    const res = await fetch('data/utleie.json')
    const data = await res.json()

    return data.utleie
}